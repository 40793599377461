import React, { useEffect, useState } from 'react';
import './HomePage.css';

const typingDelay = 100;
const erasingDelay = 50;
const newTextDelay = 1000; // Delay between current and next word

const HomePage = () => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const words = [
    "Esports Participants",
    "Anime Reviews & Podcasts",
    "Gaming Reviews & Podcasts",
    "Live Streaming",
    "Cosplaying",
    "Anime & Gaming based clothing"
  ];

  useEffect(() => {
    if (subIndex === words[index].length + 1 && !isDeleting) {
      setTimeout(() => setIsDeleting(true), newTextDelay);
    } else if (subIndex === 0 && isDeleting) {
      setIsDeleting(false);
      setIndex((prev) => (prev + 1 === words.length ? 0 : prev + 1));
    }

    const timeout = setTimeout(() => {
      setText(words[index].substring(0, subIndex));
      setSubIndex((prev) => prev + (isDeleting ? -1 : 1));
    }, isDeleting ? erasingDelay : typingDelay);

    return () => clearTimeout(timeout);
  }, [subIndex, index, isDeleting, words]);

  useEffect(() => {
    const blinkTimeout = setTimeout(() => setBlink((prev) => !prev), 500);
    return () => clearTimeout(blinkTimeout);
  }, [blink]);

  return (
    <div className="landing-container">
      <h1 className="title">Helakaami</h1>
      <p className="typing-text">
        {text}
        <span className={`cursor ${blink ? 'blink' : ''}`}>|</span>
      </p>
      <p className="discord-join"><a href='https://discord.gg/5H5W38F3'>Join our Discord</a></p>
      <p className="launching-soon">Launching Soon</p>
    </div>
  );
};

export default HomePage;
